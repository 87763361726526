import React from "react";
import { Segment, Container } from "semantic-ui-react";
import "./Heading.css";

const Heading = ({
  heading,
  sub_heading,
  handleClick,
  noBottomMargin = false,
  statementSectionHeading,
}) => {
  return (
    <Segment.Group
      horizontal
      compact
      onClick={handleClick}
      className={noBottomMargin ? "bg color4 txt color5" : "bg color2"}
      style={{
        borderTopWidth: 0,
        marginTop: noBottomMargin ? 15 : 0,
        borderRadius: 0,
        border: "none",
        boxShadow: "none",
        marginBottom: noBottomMargin ? "0" : undefined,
        backgroundColor: noBottomMargin ? "" : undefined,
        color: noBottomMargin ? "" : undefined,
      }}
    >
      <Container
        as={Segment}
        textAlign="center"
        compact
        className={"color5"}
        style={{
          width: "80%",
          border: "none",
          color: noBottomMargin ? "" : undefined,
        }}
      >
        <h3
          style={{ marginBottom: sub_heading ? "0.75rem" : "0" }}
          dangerouslySetInnerHTML={{ __html: heading }}
          data-testid={statementSectionHeading ? undefined : "heading"}
        />

        {sub_heading && (
          <div
            id="subheading"
            dangerouslySetInnerHTML={{ __html: sub_heading }}
            data-testid={statementSectionHeading ? undefined : "subheading"}
          />
        )}
      </Container>
    </Segment.Group>
  );
};

export default Heading;
