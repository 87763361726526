import React, { Component } from "react";
import { Radio, Form } from "semantic-ui-react";

class RadioButtons extends Component {
  render() {
    const { options, value, onChange, disabled, name } = this.props;

    return (
      <Form>
        {options.map((option) => {
          return (
            <Form.Field key={option.option_value}>
              <Radio
                disabled={disabled}
                key={option.option_value}
                label={option.option_text}
                value={option.option_value}
                checked={value === option.option_value}
                onChange={onChange}
                name={name}
              />
            </Form.Field>
          );
        })}
      </Form>
    );
  }
}

export default RadioButtons;
