import React from "react";
import { Segment } from "semantic-ui-react";
import Heading from "../Heading";
import Statement from "./Statement";

const StatementList = ({
  editMode,
  statements,
  selectStatement,
  title,
  comment,
}) => {
  const renderStatement = (statement) => (
    <Statement
      editMode={editMode}
      key={statement.id}
      handleClick={() => selectStatement(statement)}
      {...statement}
    />
  );

  const normalStatements = statements.filter((s) => s.id >= 0);
  const settingStatements = statements
    .filter((s) => s.id < 0)
    .filter((s) => editMode || s.attributes.action !== "add_activity");

  return (
    <>
      {title && (
        <Heading
          heading={title}
          sub_heading={comment}
          noBottomMargin={true}
          statementSectionHeading={true}
        />
      )}
      {normalStatements.length > 0 && (
        <Segment.Group style={title ? { marginTop: 0 } : undefined}>
          {normalStatements.map(renderStatement)}
        </Segment.Group>
      )}
      {settingStatements.length > 0 && (
        <Segment.Group
          style={
            title && normalStatements.length === 0
              ? { marginTop: 0 }
              : undefined
          }
        >
          {settingStatements.map(renderStatement)}
        </Segment.Group>
      )}
    </>
  );
};

export default StatementList;
