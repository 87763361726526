import React, { Component } from "react";
import { Menu, Button, Container, Header, Responsive } from "semantic-ui-react";
import { desktopScreenWidth } from "../config/sizes";
import "./TopBar.css";
import { Link } from "react-router-dom";

class TopBar extends Component {
  state = {
    homeLoading: false,
    upLoading: false,
  };

  content = (hidden) => {
    const { menu, home, up, apiRequest } = this.props;
    const { homeLoading, upLoading } = this.state;

    const handleUpClick = () => {
      this.setState({ upLoading: true });
      apiRequest(up).then(() => this.setState({ upLoading: false }));
    };
    const handleHomeClick = () => {
      this.setState({ homeLoading: true });
      apiRequest(home).then(() => this.setState({ homeLoading: false }));
    };
    const isHome = this.props.url && this.props.url === "/api/v1/";

    return (
      <Container as={Menu} inverted>
        {home && (
          <Responsive
            as={Menu.Item}
            minWidth={desktopScreenWidth + 1}
            className="horizontal compact"
          >
            <Button
              onMouseDown={(e) => e.preventDefault()}
              loading={homeLoading}
              disabled={homeLoading}
              style={{ visibility: !home ? "hidden" : "inherit" }}
              onClick={handleHomeClick}
              size="big"
              inverted
              icon={isHome ? "list" : "home"}
              as={Link}
              to={home}
            />
          </Responsive>
        )}
        <Responsive
          as={Menu.Item}
          maxWidth={desktopScreenWidth}
          className="horizontal compact"
        >
          <Button
            onMouseDown={(e) => e.preventDefault()}
            onClick={menu}
            size="big"
            inverted
            icon="sidebar"
          />
        </Responsive>
        <Menu.Item
          className="horizontal compact"
          style={{ margin: "auto", flexDirection: "column", maxWidth: "68vw" }}
        >
          <Header
            textAlign="center"
            style={{ marginBottom: 0 }}
            inverted
            as="h3"
            data-testid={hidden ? undefined : "top-bar-title"}
          >
            {this.props.title}
          </Header>
          {this.props.sub_title && this.props.sub_title.length > 0 && (
            <Header
              textAlign="center"
              style={{ marginTop: 0 }}
              inverted
              as="h4"
              data-testid={hidden ? undefined : "top-bar-sub-title"}
            >
              {this.props.sub_title}
            </Header>
          )}
        </Menu.Item>
        {up && (
          <Menu.Item className="horizontal compact">
            <Button
              onMouseDown={(e) => e.preventDefault()}
              loading={upLoading}
              disabled={upLoading}
              style={{ visibility: !up ? "hidden" : "inherit" }}
              size="big"
              inverted
              icon="chevron left"
              onClick={handleUpClick}
              as={Link}
              to={up}
              data-testid={hidden ? undefined : "top-bar-back-button"}
            />
          </Menu.Item>
        )}
      </Container>
    );
  };

  render() {
    return (
      <>
        <div id="top-bar" className="bg color1">
          {this.content(false)}
        </div>
        <div id="top-bar-hidden">{this.content(true)}</div>
      </>
    );
  }
}

export default TopBar;
