import React from "react";
import { Segment, Icon, Header, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import StatementLight from "./StatementLight";
import "./Statement.css";

const getActionIcon = (attributes, editMode) => {
  if (attributes.email_link) return "envelope";
  if (attributes.action === "add_activity") return "check";
  if (editMode) return "x";
  return "chevron right";
};

const Statement = ({ attributes, handleClick, loading, editMode }) => {
  const clickable = !!attributes.link || !!attributes.email_link;
  return (
    <Segment.Group
      horizontal
      compact
      onClick={!loading && clickable ? handleClick : undefined}
      className={`statement ${clickable ? "" : "disabled"}`}
      as={clickable ? Link : "div"}
      to={clickable ? attributes.link : undefined}
    >
      <Segment compact>
        <StatementLight {...attributes} />
      </Segment>
      <Segment compact style={{ width: "80%", border: "none" }}>
        <Header as="h4" className="txt color1">
          {attributes.title}
        </Header>
        {attributes.action === "add_activity" ? (
          <Input fluid placeholder={attributes.sub_title} />
        ) : (
          attributes.sub_title
        )}
      </Segment>
      <Segment
        compact
        className="action-icon-holder"
        style={{ margin: "auto", borderLeft: "none" }}
      >
        <Icon
          style={{ visibility: clickable ? "unset" : "hidden" }}
          loading={loading}
          name={loading ? "circle notch" : getActionIcon(attributes, editMode)}
          size="large"
        />
      </Segment>
    </Segment.Group>
  );
};

export default Statement;
