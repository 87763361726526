import React from "react";
import { Message } from "semantic-ui-react";

export default ({ title, text, level }) => {
  return (
    <Message
      style={{ marginBottom: "1rem" }}
      info={level === "info"}
      success={level === "success"}
      positive={level === "positive"}
      negative={level === "negative" || level === "error"}
      warning={level === "warning"}
      data-testid="display-message"
    >
      <Message.Header style={{ paddingBottom: "0.75rem" }}>
        {title}
      </Message.Header>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Message>
  );
};
