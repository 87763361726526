import React from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Sidebar, Header } from "semantic-ui-react";
import "./SideMenu.css";
import { siteName } from "../config/copy";

const SideMenu = ({
  home,
  up,
  help,
  about,
  privacy,
  signout,
  signin,
  visible,
  handleSidebarHide,
  handleItemClick,
  account,
  hideItems,
  sidebar_links,
  name,
}) => {
  const menuItems = [
    {
      text: "Home",
      link: home,
      icon: "home",
    },
    {
      text: name || "Account",
      link: account,
      icon: "user",
    },
    {
      text: "Sign out",
      function: signout,
      icon: "sign out",
    },
    {
      text: "Sign in",
      function: signin,
      icon: "sign in",
    },
    {
      sidebar_links,
    },
    {
      subMenu: [
        {
          text: "Help",
          link: help,
          icon: "help",
        },
        {
          text: "About",
          link: about,
          icon: "info",
        },
        {
          text: "Privacy",
          link: privacy,
          icon: "lock",
        },
      ],
    },
  ];

  const renderSubmenu = (item) => (
    <Menu.Item key={"submenu"} style={{ marginTop: "auto" }}>
      <Menu.Menu key={"sub sub menu"}>
        {item.subMenu
          .filter((item) => item.link || item.function)
          .map((item) => renderMenuItem(item, false))}
      </Menu.Menu>
    </Menu.Item>
  );

  const renderMenuItem = (item, showIcon = true) => (
    <Menu.Item
      key={item.text || item.title}
      as={
        item.link
          ? Link
          : ["sign out", "sign in"].includes(item.icon)
          ? "a"
          : "div"
      }
      to={item.link}
      onClick={item.function || (() => handleItemClick(item.link))}
    >
      {showIcon && item.icon && <Icon key={item.icon} name={item.icon} />}
      {item.text || item.title}
    </Menu.Item>
  );

  const renderSidebarLinks = (item, i) => (
    <React.Fragment key={"fragment" + i}>
      <Menu.Item key={"sidebarlinkspacer" + i} />
      {item.sidebar_links.map((item) => renderMenuItem(item))}
    </React.Fragment>
  );

  return (
    <Sidebar
      as={Menu}
      id="sidemenu"
      inverted
      animation="overlay"
      onHide={handleSidebarHide}
      vertical
      visible={visible}
      size="large"
      style={{ display: "flex" }}
    >
      <Menu.Item key="header">
        <Header key={"headertext"} inverted as="h3">
          <Link style={{ color: "#ffffff" }} to="/">
            {siteName}
          </Link>
        </Header>
      </Menu.Item>
      {!hideItems &&
        menuItems
          .filter(
            (item) =>
              item.link || item.function || item.subMenu || item.sidebar_links
          )
          .map((item) => {
            if (item.subMenu) return renderSubmenu(item);
            else if (item.sidebar_links) return renderSidebarLinks(item);
            else return renderMenuItem(item);
          })}
    </Sidebar>
  );
};

export default SideMenu;
